import React, { ChangeEventHandler, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './ReviewInDescription.scss';

interface ReviewInDescriptionProps {
  initialValue: string | null;
  error: boolean;
}

const ReviewInDescription = ({ initialValue, error }: ReviewInDescriptionProps) => {
  const { t } = useTranslation();
  const [value, setValue] = useState(initialValue);

  const handleChange: ChangeEventHandler = (e) => setValue(e.currentTarget.value);

  return (
    <div id="review-in-description">
      <textarea
        name="description"
        placeholder={t('review_in_description_placeholder')}
        value={value}
        onChange={handleChange}
        className={error ? 'error' : undefined}
      />
      {error && <span className="error">{t('error-required')}</span>}
    </div>
  );
};

export default ReviewInDescription;
