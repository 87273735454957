export interface Review {
  user: string;
  date: string;
  value: number;
  content: string;
}

export namespace Review {
  export enum Platform {
    Trustpilot = 'trustpilot',
    Google = 'google',
  }

  export const platformLabel: Record<Platform, string> = {
    [Platform.Google]: 'Google',
    [Platform.Trustpilot]: 'Trustpilot',
  };

  export const platformUrl: Record<Platform, string> = {
    [Platform.Google]: 'https://g.page/r/CfYx4EggOnU1EAE/review',
    [Platform.Trustpilot]: 'https://fr.trustpilot.com/evaluate/bluevalet.fr',
  };

  export interface OutData {
    platform: Platform;
    voucher: string;
  }
}

export const Notification = {
  Friends: 'FRIENDS',
  SocialNetworks: 'SOCIAL_NETWORKS',
  Partnership: 'PARTNERSHIP',
  Advertising: 'ADVERTISING',
  SearchEngine: 'SEARCH_ENGINE',
  Press: 'PRESS',
  Other: 'OTHER',
};

export interface ReviewInData {
  score: number;
  description: string | null;
}
