import React from 'react';
import { FC } from 'react';
import { MantineProvider, MantineThemeOverride } from '@mantine/core';

const theme: MantineThemeOverride = {
  colors: {
    blue: [
      '#d0ebff', // mantine blue.1
      '#a5d8ff', // mantine blue.2
      '#74c0fc', // mantine blue.3
      '#4dabf7', // mantine blue.4
      '#339af0', // mantine blue.5
      '#4169e1', // warm-blue
      '#1437b9', // blue-blue
      '#001c86', // medium-blue
      '#001875', // dark-blue
      '#1b2845', // dark-blue-grey
    ],
  },
  primaryColor: 'blue',
  primaryShade: 6,
  fontFamily: 'AvenirNext',
};

export const MantineTheme: FC = ({ children }) => {
  // todo: add withNormalizeCSS and fix broken styles
  return (
    <MantineProvider theme={theme} withGlobalStyles>
      {children}
    </MantineProvider>
  );
};
