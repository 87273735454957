import React from 'react';
import { useTranslation } from 'react-i18next';
import Review from '@modules/reviews/components/Review';

export default function Reviews({ siteCode }) {
  const { t } = useTranslation();
  const getReviewsAuthor = number => t(`lp-parking-${siteCode}-reviews-${number}-name`);
  const getReviewsContent = number => t(`lp-parking-${siteCode}-reviews-${number}-content`);
  const reviewScore = 5;
  return (
    <div className="customerReviewsContainer">
      {[1, 2, 3].map((reviewNumber, index) => (
        <Review
          key={index}
          review={{
            author: getReviewsAuthor(reviewNumber),
            content: getReviewsContent(reviewNumber),
            reviewScore,
          }}
        />
      ))}
    </div>
  );
}
