import ReactDOM from 'react-dom';
import React from 'react';
import { I18nextProvider } from 'react-i18next';
import i18n from 'i18n';
import ReviewInForm from './components/in/form/ReviewInForm';
import { ReviewInData } from './model'; // eslint-disable-line no-unused-vars

declare var __INITIAL_DATA__: ReviewInData;

const ReviewIn = (ctx) => {
  const container = document.getElementById('ReviewInRoot');

  if (container) {
    ReactDOM.render(
      <React.Suspense fallback={<div />}>
        <I18nextProvider i18n={i18n}>
          <ReviewInForm id={ctx.params.id} data={__INITIAL_DATA__} />
        </I18nextProvider>
      </React.Suspense>,
      container,
    );
  }
};

export default ReviewIn;
