/* @flow */

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { compose } from 'recompose';
import { Input } from '@bluevalet/common-ui';
import IntlTelInput from 'intl-tel-input/react/build/IntlTelInput.cjs';

import { withOrder } from '../withOrder';
import { updateInformation } from '../../actions/information';
import { getInformation } from '../../reducers/information';
import { getTravelType } from '../../reducers/cart';
import { getBooking } from '../../reducers/booking';
import { TravelTypes } from '../../types';
import { isErrorField } from '../../utils';
import TravelInfo from '../TravelInfo';
import TravelType from '../TravelType';
import SiteInfo from '../SiteInfo';
import { NextStepButton } from '../NextStepButton';

import type { UserInformation } from '../../types';
import { getEndTrip } from '../../reducers/trip';

type Props = {
  t: (key: string) => string,
  i18n: any,
  information?: UserInformation,
  travelType?: string,
  booking?: any,
  endTrip?: any,
  forceValidation: boolean,
  updateInformation: () => void,
};

function InformationStep({
  t,
  i18n,
  information,
  travelType,
  booking,
  forceValidation: initialForceValidation,
  updateInformation,
  endTrip,
}: Props) {
  const [isPhoneValid, setIsPhoneValid] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [forceValidation, setForceValidation] = useState(initialForceValidation);

  const onChange = (field, event) => {
    updateInformation({
      ...information,
      [field]: event?.target?.value,
    });
  };
  const onChangeAddress = (field, event) => {
    updateInformation({
      ...information,
      address: {
        ...information.address,
        [field]: event?.target?.value,
      },
    });
  };
  const onChangeVehicle = (field, event) => {
    updateInformation({
      ...information,
      vehicle: {
        ...information.vehicle,
        [field]: event?.target?.value,
      },
    });
  };
  const onChangePhone = phoneNumber => {
    updateInformation({
      ...information,
      phoneNumber,
    });
  };

  const scrollToFirstError = () => {
    const scrollOptions = { behavior: 'smooth' };
    const scrollTo = selector => {
      const elem = document.querySelector(selector);
      if (elem) {
        elem.scrollIntoView(scrollOptions);
      }
    };
    if (!travelType) {
      scrollTo('#FieldsetTravelType');
    } else if (!information?.lastName || !information?.firstName || !isPhoneValid) {
      scrollTo('#FieldsetCustomer');
    } else if (!information?.vehicle?.kind || !information?.vehicle?.color) {
      scrollTo('#FieldsetVehicle');
    } else if (!booking?.departure?.checkpoint || !booking?.arrival?.checkpoint) {
      scrollTo('#FieldsetTrip');
    } else if (
      !booking?.arrival?.tripNumber ||
      !(endTrip?.isForced || endTrip?.isUnknown || (endTrip?.isValid && booking.isArrivalSlotAvailable))
    ) {
      scrollTo('#FieldsetTrip .Trip');
    }
  };

  const onDisabledSubmit = () => {
    setForceValidation(true);
    scrollToFirstError();
  };

  const isError = field => isErrorField(forceValidation, field);
  const getErrorMessage = field => {
    if (isError(field)) {
      return t('error-required');
    }
    return null;
  };

  // ⚠ AWFUL STUFF HERE
  // because the onChangeValidity is not triggered with an initialValue,
  // but we need the input & utils file loaded for that...
  // Might be fixed in a later release of intl-tel-input
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (window.intlTelInputGlobals?.instances[0]) {
        window.intlTelInputGlobals.instances[0].promise.then(() => {
          setIsPhoneValid(window.intlTelInputGlobals.instances[0].isValidNumber());
        });
      }
    }, 600);
    return () => clearTimeout(timeoutId);
  }, []);

  const phoneNumber = information?.phoneNumber;

  useEffect(() => {
    if (window.intlTelInputGlobals?.instances[0]) {
      window.intlTelInputGlobals.instances[0].promise.then(() => {
        window.intlTelInputGlobals.instances[0].setNumber(phoneNumber);
        setIsPhoneValid(window.intlTelInputGlobals.instances[0].isValidNumber());
      });
    }
  }, [phoneNumber]);

  useEffect(() => {
    setIsFormValid(
      information?.lastName &&
        information?.firstName &&
        information?.vehicle?.kind &&
        information?.vehicle?.color &&
        travelType &&
        isPhoneValid &&
        booking?.departure?.checkpoint &&
        booking?.arrival?.checkpoint &&
        booking?.arrival?.tripNumber &&
        (endTrip?.isForced || endTrip?.isUnknown || (endTrip?.isValid && booking.isArrivalSlotAvailable)),
    );
  }, [information, travelType, booking, isPhoneValid, endTrip]);

  return (
    <div id="order-informations" className="bookingInformation bookingLogin bookingStep active">
      <h2>4. {t('booking-detail')}</h2>
      <div className="booking-content">
        <TravelType forceValidation={forceValidation} />

        <fieldset id="FieldsetCustomer">
          <legend>{t('booking-info')}</legend>
          <div className="form">
            <div className="field">
              <Input
                data-cy="lastName"
                id="lastName"
                name="lastName"
                label={`${t('lastName')} *`}
                type="text"
                placeholder={t('lastName-placeholder')}
                value={information?.lastName}
                onChange={value => onChange('lastName', value)}
                error={isError(information?.lastName)}
                message={getErrorMessage(information?.lastName)}
              />
            </div>
            <div className="field">
              <Input
                data-cy="firstName"
                id="firstName"
                name="firstName"
                label={`${t('firstName')} *`}
                type="text"
                placeholder={t('firstName-placeholder')}
                value={information?.firstName}
                onChange={value => onChange('firstName', value)}
                error={isError(information?.firstName)}
                message={getErrorMessage(information?.firstName)}
              />
            </div>
            <div className="field">
              <div className="label">
                <span className="input-label" title="Nom">
                  {`${t('phoneNumber')} *`}
                </span>
              </div>
              <IntlTelInput
                initialValue={information?.phoneNumber}
                onChangeNumber={onChangePhone}
                onChangeValidity={setIsPhoneValid}
                initOptions={{
                  initialCountry: i18n.language,
                  preferredCountries: ['FR', 'ES', 'BE', 'PT', 'IT', 'GB', 'DE'],
                  utilsScript: '/assets/static/js/intl-tel-input/build/js/utils.js',
                  separateDialCode: true,
                  showSelectedDialCode: true,
                  countrySearch: false,
                  autoPlaceholder: 'aggressive',
                }}
              />
              <input type="hidden" name="phoneNumber" value={information.phoneNumber} />
              <input type="hidden" name="phoneNumber-valid" value={isPhoneValid} />
              <input type="hidden" name="email" value={information.email} />
            </div>
            {travelType === TravelTypes.pro && (
              <div className="field">
                <Input
                  data-cy="company"
                  id="company"
                  name="company"
                  label={t('company')}
                  type="text"
                  placeholder={t('company-placeholder')}
                  value={information?.company}
                  onChange={value => onChange('company', value)}
                />
              </div>
            )}
          </div>
        </fieldset>

        {travelType === TravelTypes.pro && (
          <fieldset id="FieldsetAddress">
            <legend>{t('booking-address')}</legend>
            <div className="form">
              <div className="field">
                <Input
                  data-cy="addressFirstLine"
                  id="addressFirstLine"
                  name="address.firstLine"
                  label={t('addressFirstLine')}
                  type="text"
                  placeholder={t('addressFirstLine-placeholder')}
                  value={information?.address?.firstLine}
                  onChange={value => onChangeAddress('firstLine', value)}
                />
              </div>
              <div className="field">
                <Input
                  data-cy="addressSecondLine"
                  id="addressSecondLine"
                  name="address.secondLine"
                  label={t('addressSecondLine')}
                  type="text"
                  placeholder={t('addressSecondLine-placeholder')}
                  value={information?.address?.secondLine}
                  onChange={value => onChangeAddress('secondLine', value)}
                />
              </div>
              <div className="field">
                <Input
                  data-cy="zipCode"
                  id="zipCode"
                  name="address.zipCode"
                  label={t('addressZipCode')}
                  type="text"
                  placeholder={t('addressZipCode-placeholder')}
                  value={information?.address?.zipCode}
                  onChange={value => onChangeAddress('zipCode', value)}
                />
              </div>
              <div className="field">
                <Input
                  data-cy="city"
                  id="city"
                  name="address.city"
                  label={t('addressCity')}
                  type="text"
                  placeholder={t('addressCity-placeholder')}
                  value={information?.address?.city}
                  onChange={value => onChangeAddress('city', value)}
                />
              </div>
              <div className="field">
                <Input
                  data-cy="country"
                  id="country"
                  name="address.country"
                  label={t('addressCountry')}
                  type="text"
                  placeholder={t('addressCountry-placeholder')}
                  value={information?.address?.country}
                  onChange={value => onChangeAddress('country', value)}
                />
              </div>
            </div>
          </fieldset>
        )}

        <fieldset id="FieldsetVehicle">
          <legend>{t('booking-vehicle-legend')}</legend>
          <div className="form">
            <div className="field">
              <Input
                data-cy="vehicle.kind"
                id="vehicle.kind"
                name="vehicle.kind"
                label={`${t('vehicleKind')} *`}
                type="text"
                placeholder={t('vehicleKind-placeholder')}
                value={information?.vehicle?.kind || ''}
                onChange={value => onChangeVehicle('kind', value)}
                error={isError(information?.vehicle?.kind)}
                message={getErrorMessage(information?.vehicle?.kind)}
              />
            </div>
            <div className="field">
              <Input
                data-cy="vehicle.color"
                id="vehicleColor"
                name="vehicle.color"
                label={`${t('vehicleColor')} *`}
                type="text"
                placeholder={t('vehicleColor-placeholder')}
                value={information?.vehicle?.color || ''}
                onChange={value => onChangeVehicle('color', value)}
                error={isError(information?.vehicle?.color)}
                message={getErrorMessage(information?.vehicle?.color)}
              />
            </div>
          </div>
          <SiteInfo />
        </fieldset>

        <fieldset id="FieldsetTrip">
          <legend>{t('booking-trip-legend')}</legend>
          <div className="form travelInfoForm">
            {travelType === TravelTypes.bvb && (
              <div className="field">
                <Input
                  data-cy="expensesTitle"
                  id="expensesTitle"
                  name="expensesTitle"
                  label={t('expensesTitle')}
                  type="text"
                  placeholder={t('expensesTitle-placeholder')}
                  value={information?.expensesTitle}
                  onChange={value => onChangeVehicle('expensesTitle', value)}
                />
              </div>
            )}
            <TravelInfo forceValidation={forceValidation} />
          </div>
        </fieldset>
      </div>

      <NextStepButton label={t('booking-go-to-payment')} disabled={!isFormValid} onDisabledClick={onDisabledSubmit} />
    </div>
  );
}

const mapStateToProps = state => ({
  information: getInformation(state),
  travelType: getTravelType(state),
  booking: getBooking(state),
  endTrip: getEndTrip(state),
});

export default compose(withOrder, connect(mapStateToProps, { updateInformation }), withTranslation())(InformationStep);
