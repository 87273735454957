import React, { createElement } from 'react';
import { SocialMediaIcon, SocialMediaLink } from './model';
import './SocialMedias.scss';

interface SocialMediasProps {
  medias: Array<string>;
}

const SocialMedias = ({ medias }: SocialMediasProps) => (medias.length > 0 ? (
  <ul className="social-medias">
    {medias.map((media) => (
      <li key={media}>
        <a
          href={SocialMediaLink[media]}
          target="_blank"
          rel="noopener noreferrer"
          aria-label={`blue ${media} account link`}
        >
          {createElement(SocialMediaIcon[media])}
        </a>
      </li>
    ))}
  </ul>
) : null);

export default SocialMedias;
