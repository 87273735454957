import React, { FC, useEffect } from 'react';
import { useDisclosure } from '@mantine/hooks';
import { Modal } from '@mantine/core';
import { Review } from '@modules/reviews/model';
import {
  ShareReviewModalContentWithoutVoucher,
  ShareReviewModalContentWithVoucher,
} from '@modules/reviews/components/out/ShareReviewModalContent';

interface ShareReviewModalProps {
  platform: Review.Platform;
  voucher: string;
}

export const ShareReviewModal: FC<ShareReviewModalProps> = ({ platform, voucher }) => {
  const [isVoucherShown, { open: showVoucher, close: closeVoucher }] = useDisclosure(false);
  const [modalOpened, { open: openModal, close: closeModal }] = useDisclosure(false);

  useEffect(() => {
    const abortController = new AbortController();
    const submitReviewButton = document.getElementById('submit-review') as HTMLButtonElement | null;
    const formReview = document.getElementById('review-form') as HTMLFormElement | null;

    if (submitReviewButton && formReview) {
      const handleClick = (event: MouseEvent) => {
        event.preventDefault();
        const formData = new FormData(formReview);
        const score = formData.get('score');
        if (score === '5') openModal();
        else formReview.action += '&referer=/reviews/out/success';
        formReview.submit();
      };

      submitReviewButton.addEventListener('click', handleClick, { signal: abortController.signal });
    }

    return () => abortController.abort();
  }, [openModal]);

  const handleClose = () => {
    window.location.replace(isVoucherShown ? '/' : '/reviews/out/success');

    closeModal();
    closeVoucher();
  };

  return (
    <Modal
      size="lg"
      radius={12}
      opened={modalOpened}
      onClose={handleClose}
      styles={{
        body: {
          padding: 40,
        },
        header: {
          paddingBottom: 0,
        },
      }}
      centered>
      {isVoucherShown ? (
        <ShareReviewModalContentWithVoucher voucher={voucher} />
      ) : (
        <ShareReviewModalContentWithoutVoucher platform={platform} onReviewed={showVoucher} />
      )}
    </Modal>
  );
};
