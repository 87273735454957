import React from 'react';
import { useTranslation } from 'react-i18next';
import SocialMedias from '../../../../shared/components/social/SocialMedias';
import { SocialMedia } from '../../../../shared/components/social/model';
import ReviewInLayout from './ReviewInLayout';
import './ReviewInSuccessContent.scss';

const ReviewInSuccessContent = () => {
  const { t } = useTranslation();

  return (
    <ReviewInLayout className="success">
      <div className="text-container">
        <p className="title">{t('review_in_success_title')}</p>
        <p className="subtitle">{t('review_in_success_subtitle')}</p>
      </div>
      <SocialMedias medias={[SocialMedia.Facebook, SocialMedia.Instagram, SocialMedia.Linkedin]} />
    </ReviewInLayout>
  );
};

export default ReviewInSuccessContent;
