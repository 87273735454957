import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import './RatingStarsInput.scss';

interface RatingStarProps {
  number: number;
  onClick: (index: number) => void;
  active: boolean;
}

const RatingStar = ({ number, onClick, active }: RatingStarProps) => {
  const { t } = useTranslation();
  const handleClick = () => onClick(number);

  return (
    <li data-value={number} className={active ? 'active' : null}>
      <button type="button" onClick={handleClick}>
        <div className="star" />
        <span>{t(`review_in_score_${number}`)}</span>
      </button>
    </li>
  );
};

interface RatingStarsInputProps {
  initialValue: number;
  length?: number;
}

const RatingStarsInput = ({ initialValue, length = 5 }: RatingStarsInputProps) => {
  const [value, setValue] = useState(initialValue);

  return (
    <div className="stars">
      <input type="hidden" name="score" value={value} />
      <ul>
        {Array.from({ length }).map((_, index) => (
          <RatingStar
            key={index}
            number={index + 1}
            onClick={setValue}
            active={index + 1 === value}
          />
        ))}
      </ul>
    </div>
  );
};

export default RatingStarsInput;
