import ReactDOM from 'react-dom';
import React from 'react';
import { I18nextProvider } from 'react-i18next';
import i18n from '../../i18n';
import ReviewInSuccessContent from './components/in/ReviewInSuccessContent';

const ReviewInSuccess = () => {
  const container = document.getElementById('ReviewInSuccessRoot');

  if (container) {
    ReactDOM.render(
      <React.Suspense fallback={<div />}>
        <I18nextProvider i18n={i18n}>
          <ReviewInSuccessContent />
        </I18nextProvider>
      </React.Suspense>,
      container,
    );
  }
};

export default ReviewInSuccess;
