// todo better types
export const hasErrors = (errors: {}): boolean =>
  Object.values(errors).some((e) => (typeof e === 'object' ? hasErrors(e) : Boolean(e)));

export const setError = (errors: {}, field: string, message?: string) => ({
  ...errors,
  [field]: message ?? true,
});

export const unsetError = (errors: {}, field: string) => ({
  ...errors,
  [field]: false,
});

export const checkField = (errors: {}, field: string, condition: boolean, message?: string) =>
  condition ? setError(errors, field, message) : unsetError(errors, field);
