import { ReactComponent as FacebookIcon } from '../../../static/images/social/facebook.svg';
import { ReactComponent as InstagramIcon } from '../../../static/images/social/instagram.svg';
import { ReactComponent as TwitterIcon } from '../../../static/images/social/twitter.svg';
import { ReactComponent as LinkedinIcon } from '../../../static/images/social/linkedin.svg';

export const SocialMedia = {
  Facebook: 'facebook',
  Instagram: 'instagram',
  Twitter: 'twitter',
  Linkedin: 'linkedin',
};

export const SocialMediaLink = {
  [SocialMedia.Facebook]: 'https://www.facebook.com/bluevalet',
  [SocialMedia.Instagram]: 'https://www.instagram.com/blue.valet',
  [SocialMedia.Twitter]: 'https://twitter.com/blue_valet',
  [SocialMedia.Linkedin]: 'https://www.linkedin.com/company/blue-valet',
};

export const SocialMediaIcon = {
  [SocialMedia.Facebook]: FacebookIcon,
  [SocialMedia.Instagram]: InstagramIcon,
  [SocialMedia.Twitter]: TwitterIcon,
  [SocialMedia.Linkedin]: LinkedinIcon,
};
