import React from 'react';
import { useTranslation } from 'react-i18next';
import { getExternalReviewsData } from '@utils/ratingReviews';
import RatingStars from './RatingStars';

const ExternalRatings = () => {
  const { t } = useTranslation();
  const externalReviewsData = getExternalReviewsData();
  return (
    <>
      {externalReviewsData.map(({ rate, amount, name, link }, i) => (
        <React.Fragment key={i}>
          {i >= 1 && <div className="dotted-separation" />}
          <div className="rating">
            <RatingStars rating={rate} />
            <p className="ratingScore">
              {rate}
              <span>/5</span>
            </p>
            <a target="blank" href={link}>
              <p className="sourceData">
                +{amount} {t('reviews')} <strong>{name}</strong>
              </p>
            </a>
          </div>
        </React.Fragment>
      ))}
    </>
  );
};

export default ExternalRatings;
