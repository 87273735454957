import React, { useRef, MutableRefObject, useState } from 'react';
import { useTranslation } from 'react-i18next';
import isEmpty from 'lodash.isempty';
import RatingStarsInput from './stars/RatingStarsInput';
import ReviewInLayout from '../ReviewInLayout';
import ReviewInDescription from './description/ReviewInDescription';
import { ReviewInData } from '../../../model';
import './ReviewInForm.scss';
import { checkField, hasErrors } from '../../../../../utils/form';

interface ReviewInFormProps {
  id: string;
  data: ReviewInData;
}

const ReviewInForm = ({ data }: ReviewInFormProps) => {
  const ref: MutableRefObject<HTMLFormElement> = useRef(null);
  // todo typescript construct errors from data (boolean or string, cf. react-hook-form)
  const [errors, setErrors] = useState({ score: false, description: false });
  const { t } = useTranslation();

  const handleSubmit = () => {
    const form = ref.current.elements;
    const errorsWithDescription = checkField(
      errors,
      'description',
      form.score.value < 5 && isEmpty(form.description.value),
    );
    setErrors(errorsWithDescription);
    if (!hasErrors(errorsWithDescription)) ref.current.requestSubmit();
  };

  return (
    <ReviewInLayout>
      <form method="POST" ref={ref}>
        <p>{t('review_in_subtitle')}</p>

        <h2>1. {t('review_in_score_title')}</h2>

        <RatingStarsInput initialValue={data.score} />

        <h2>2. {t('review_in_description_title')}</h2>

        <ReviewInDescription initialValue={data.description} error={!!errors.description} />

        <button type="button" onClick={handleSubmit}>
          {t('review_in_submit')}
        </button>
      </form>
    </ReviewInLayout>
  );
};

export default ReviewInForm;
