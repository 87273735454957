import React from 'react';
import logo from '../../../../static/images/logo.svg';
import './ReviewInLayout.scss';

interface ReviewInLayoutProps {
  className?: string;
}

const ReviewInLayout = ({ children, className }: ReviewInLayoutProps) => {
  const hasBanner = document.getElementsByClassName('smartbanner').length > 0;
  const classNameProps = [hasBanner && 'smartbanner', className].filter(Boolean).join(' ');
  return (
    <div id="review-in-layout" className={classNameProps}>
      <img src={logo} alt="Blue Valet" />
      {children}
    </div>
  );
};

export default ReviewInLayout;
