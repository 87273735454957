import $ from 'jquery';
import { Notification } from './model';
import ReactDOM from 'react-dom';
import React from 'react';
import { I18nextProvider } from 'react-i18next';
import i18n from 'i18n';
import { ShareReviewModal } from './components/out/ShareReviewModal';
import { MantineTheme } from '@styles/mantine';
import { Review } from '@modules/reviews/model';

declare var __INITIAL_DATA__: Review.OutData;

const $formReview = $('#review-form');

function bindScore(name: string) {
  const $input = $formReview.find(`input[name="${name}"]`);
  const $container = $input.parent();

  const setInputValue = (value: string | undefined) => $input.val(value ?? '');

  const setElementActive = ($element: JQuery<HTMLLIElement>) => {
    $element.addClass('active');
    $container.find('li').not($element).removeClass('active');
  };

  $container.find('li').on('click', event => {
    const $star = $(event.currentTarget);
    const value = $star.attr('data-value');

    setInputValue(value);
    setElementActive($star);
  });
}

function bindSource() {
  const $source = $formReview.find('.how-you-know');
  const $input = $source.find('input[type="hidden"]');

  const setInputValue = (value: string | undefined) => $input.val(value ?? '');

  const setElementActive = ($element: JQuery<HTMLElement>) => {
    $source.find('input[type="checkbox"]').not($element).prop('checked', false);
  };

  $source.find<HTMLInputElement>('input[type="checkbox"]').on('change', event => {
    const $element = $(event.currentTarget);
    if (event.currentTarget.checked) {
      setElementActive($element);
      setInputValue(event.currentTarget.id);
    } else {
      setInputValue(undefined);
    }

    if (event.target.id === Notification.Other) $(`#${event.target.id}-text`).toggle();
  });
}

export const bindReact = () => {
  const externalRatingsRoot = document.getElementById('share-review');
  const { platform, voucher } = __INITIAL_DATA__;

  if (externalRatingsRoot) {
    ReactDOM.render(
      <React.Suspense fallback={<div />}>
        <I18nextProvider i18n={i18n}>
          <MantineTheme>
            <ShareReviewModal platform={platform} voucher={voucher} />
          </MantineTheme>
        </I18nextProvider>
      </React.Suspense>,
      externalRatingsRoot,
    );
  }
};

export default function () {
  bindScore('score');
  bindScore('valetScore');
  bindScore('adviceScore');
  bindSource();
  bindReact();
}
